import * as React from "react";
import styled from "styled-components";
import { Account, AccountInfo, Counter, Plane } from "../components/DataModels";
import { AccountBanner } from "../components/AccountBanner";
import { abilityOrMod } from "../components/stats/PlaneStatRow";
import StatsContainer from "../components/stats/StatsContainer";
import backgroundImage from "../images/web-bg.jpg";
import {
  LocalStorageInterface as LSI,
  S3BucketPrefix,
  ScreenSizes,
  configureLogoutTimer,
  createLogOutTimer,
  familyToAircraft,
  formatDate,
  getAircraftDataFromFamily,
  getScreenSize,
  isSmallScreen,
  parseActiveAccount,
} from "../shared-code";
import TopAircraftCard from "../components/stats/TopAircraftCard";
import { ContentBlock } from "../components/ContentBlock";
import "../styles/shared.css";
import PageWrapper from "../components/shared/PageWrapper";
import ContentDiv from "../components/shared/ContentDiv";
import { HeraldAPI, WardenAPI } from "../api-calls";

const AccountBannerContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column;
  }
`;

const RefreshDiv = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
`;

const EnlistedText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.8);

  &:first-child {
    font-weight: 600;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0;
    padding-top: 5px;
  }
`;

const TopAircraftContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column;
  }
`;

const TrophyText = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;

  @media (max-width: ${ScreenSizes.narrow}) {
    justify-content: flex-end;
  }
`;

const formatAndSortPlaneStats = (
  equipment: Record<string, Plane> | undefined,
  counters: Record<string, Counter> | undefined
) => {
  if (equipment != undefined) {
    const planeKeys = Object.keys(equipment);
    const planes: Plane[] = planeKeys.map((planeFamily) => {
      const planeObject: Plane = equipment[planeFamily];
      const modsAndAbilityLevels = planeObject.modsAndAbilityLevels;
      const ability = planeObject.equippedActiveAbility;
      const abilityObject: abilityOrMod = { name: "", level: 0 };
      if (ability && modsAndAbilityLevels) {
        abilityObject.name = ability;
        abilityObject.level = modsAndAbilityLevels[ability];
      }
      const mods = planeObject.equippedModsBySlotIndex;
      const modsObject: abilityOrMod[] = [
        { name: "", level: 0 },
        { name: "", level: 0 },
      ];
      if (mods && modsAndAbilityLevels) {
        modsObject[0].name = mods[1];
        modsObject[0].level = modsAndAbilityLevels[mods[1]];
        modsObject[1].name = mods[2];
        modsObject[1].level = modsAndAbilityLevels[mods[2]];
      }
      let matchesPlayed = 0;
      let matchesWon = 0;
      const aircraftId = familyToAircraft(planeFamily);
      if (
        counters != undefined &&
        aircraftId != null &&
        counters[aircraftId] != undefined
      ) {
        matchesPlayed = counters[aircraftId].pvpMatchesPlayed;
        matchesWon = counters[aircraftId].pvpMatchesWon;
      }
      return {
        planeFamily: planeFamily,
        planeLevel: planeObject.planeLevel,
        unixTimeLastFlown: planeObject.unixTimeLastFlown,
        equippedModsBySlotIndex: modsObject,
        equippedActiveAbility: ability,
        equippedPassiveAbility: null,
        modsAndAbilityLevels: null,
        trophyCount: planeObject.trophyCount,
        recentGamesWon: planeObject.recentGamesWon,
        highestTrophyCount: planeObject.highestTrophyCount,
        matchesPlayed: matchesPlayed,
        matchesWon: matchesWon,
      };
    });
    return planes;
  }
};

interface MyState {
  accounts: AccountInfo[] | undefined;
  activeAccountId: string | undefined;
  playerStats: Record<string, Account> | undefined;
  activePlayerStats: Account | undefined;
  timeOutNumber: number | undefined;
  awaitingStats: boolean;
  errorMessage: string | undefined;
}

export default class Stats extends React.Component<{}, MyState> {
  state: MyState = {
    accounts: LSI.getAccounts(),
    activeAccountId: LSI.getActiveAccountId(),
    playerStats: undefined,
    activePlayerStats: undefined,
    timeOutNumber: undefined,
    awaitingStats: false,
    errorMessage: undefined,
  };

  componentDidMount(): void {
    const email = LSI.getEmailAddress(false);
    const refreshToken = LSI.getRefreshToken();
    const shouldDelete = refreshToken == undefined;
    const starToken = LSI.getStarToken(shouldDelete);
    const starTokenExpiration = LSI.getStarTokenExpiration(false);
    if (
      starTokenExpiration != undefined &&
      starTokenExpiration < Date.now() &&
      starToken != undefined &&
      email != undefined &&
      refreshToken != undefined
    ) {
      WardenAPI.refreshStarToken(email, starToken, refreshToken);
    }
    if (
      this.state.playerStats == undefined &&
      this.state.activeAccountId != undefined &&
      this.state.awaitingStats == false
    ) {
      this.setState({ awaitingStats: true });
      HeraldAPI.getPlayerStats(
        (accs) => {
          const keys = Object.keys(accs);
          if (this.state.activeAccountId != undefined) {
            this.setState({
              playerStats: accs,
              activePlayerStats: accs[this.state.activeAccountId],
            });
          }
          this.setState({ awaitingStats: false });
        },
        (err) => {
          console.log(err);
          this.setState({ awaitingStats: false });
        }
      );
    }
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
    const timeOutNumber = configureLogoutTimer();
    if (timeOutNumber != undefined) {
      this.setState({ timeOutNumber: timeOutNumber });
    }
  }

  componentWillUnmount(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<MyState>
  ): void {
    if (
      prevState.activeAccountId != this.state.activeAccountId &&
      this.state.playerStats != undefined &&
      this.state.activeAccountId != undefined
    ) {
      this.setState({
        activePlayerStats: this.state.playerStats[this.state.activeAccountId],
      });
    }

    if (
      this.state.playerStats == undefined &&
      this.state.activeAccountId != undefined &&
      this.state.awaitingStats == false &&
      this.state.errorMessage == undefined
    ) {
      this.setState({ awaitingStats: true });
      HeraldAPI.getPlayerStats(
        (accs) => {
          const keys = Object.keys(accs);
          if (this.state.activeAccountId != undefined) {
            this.setState({ playerStats: accs });
          }
          this.setState({ awaitingStats: false });
        },
        (err) => {
          console.log(err);
          this.setState({ awaitingStats: false });
        }
      );
    }
    if (prevState.accounts == undefined && this.state.accounts != undefined) {
      if (this.state.timeOutNumber != undefined) {
        window.clearTimeout(this.state.timeOutNumber);
      }
      const timeOutNumber = configureLogoutTimer();
      if (timeOutNumber != undefined) {
        this.setState({ timeOutNumber: timeOutNumber });
      }
    }
  }

  render() {
    const planeStats = formatAndSortPlaneStats(
      this.state.activePlayerStats?.equipment,
      this.state.activePlayerStats?.playerData.careerCounters
        .countersByVehicleConfig
    );
    const account = parseActiveAccount(
      this.state.accounts,
      this.state.activeAccountId
    );

    planeStats?.sort((a, b) => (b.matchesPlayed || 0) - (a.matchesPlayed || 0));

    const mostMatchesPlane =
      planeStats != undefined
        ? getAircraftDataFromFamily(planeStats[0].planeFamily)
        : null;

    const mostMatchesPlaneStat =
      mostMatchesPlane != null && planeStats != undefined
        ? planeStats[0].matchesPlayed
        : 0;

    planeStats?.sort((a, b) => {
      const bRate =
        b.recentGamesWon.filter((result) => result == true).length /
        Math.max(b.recentGamesWon.length, 1);
      const aRate =
        a.recentGamesWon.filter((result) => result == true).length /
        Math.max(a.recentGamesWon.length, 1);
      return bRate - aRate;
    });

    const highestWinRatePlane =
      planeStats != undefined
        ? getAircraftDataFromFamily(planeStats[0].planeFamily)
        : null;

    const highestWinRatePlaneStat =
      highestWinRatePlane != null && planeStats != undefined
        ? Math.round(
            (planeStats[0].recentGamesWon.filter((result) => result == true)
              .length *
              100) /
              Math.max(planeStats[0].recentGamesWon.length, 1)
          )
        : null;

    planeStats?.sort((a, b) => b.trophyCount - a.trophyCount);
    const mostTrophiedPlane =
      planeStats != undefined
        ? getAircraftDataFromFamily(planeStats[0].planeFamily)
        : null;
    const mostTrophiedPlaneStat =
      mostTrophiedPlane != null && planeStats != undefined
        ? planeStats[0].trophyCount || 0
        : null;

    const subHeader = (acc: AccountInfo | undefined) => {
      const accountCreationTime =
        this.state.activePlayerStats?.accountDetails.creationTime.toString();

      return acc != undefined ? (
        <AccountBannerContainer>
          <AccountBanner
            player={{
              pilotIcon: acc.playerInfo?.playerIconUrl,
              pilotBanner: acc.playerInfo?.playerBannerUrl,
              playerName: acc.playerInfo?.playerName,
              guildName: acc.playerInfo?.guildName,
              guildTag: acc.playerInfo?.guildTag,
            }}
            key={acc.accountId}
            selectAccountFn={undefined}
            active={false}
          />
          {accountCreationTime != undefined ? (
            <EnlistedText>
              <span>Enlisted</span>
              <span>{formatDate(accountCreationTime)}</span>
            </EnlistedText>
          ) : null}
        </AccountBannerContainer>
      ) : null;
    };

    const startTime =
      this.state.activePlayerStats != undefined
        ? this.state.activePlayerStats.accountDetails.lastSessionStartTimeUnix
        : undefined;
    let formattedDate = undefined;
    if (startTime != undefined) {
      var a = new Date(startTime);
      var amPm = ["AM", "PM"];
      var year = a.getFullYear();
      var month = a.getMonth() + 1;
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      var sec = a.getSeconds();
      var time =
        ("0" + month).slice(-2) +
        "/" +
        ("0" + date).slice(-2) +
        "/" +
        year.toString().slice(-2) +
        " " +
        (hour % 12 == 0 ? 12 : hour % 12) +
        ":" +
        ("0" + min).slice(-2) +
        " " +
        amPm[Math.floor(hour / 12)];
      formattedDate = time;
    }

    return (
      <PageWrapper
        activeRoute="/stats"
        subHeader={subHeader(account)}
        backgroundImage={backgroundImage}
        fullScreen={false}
        forceLogin={true}
        activeAccount={account}
        accounts={this.state.accounts}
        updateAccounts={(accounts: AccountInfo[]): void => {
          this.setState({ accounts: accounts });
        }}
        updateActiveAccount={(account: AccountInfo): void => {
          this.setState({ activeAccountId: account.accountId });
        }}
        removeAccountsAndActive={() =>
          this.setState({ accounts: undefined, activeAccountId: undefined })
        }
        showLogin={undefined}
      >
        <ContentDiv
          smallDevice={isSmallScreen()}
          hasSubHeader={true}
          fullScreen={false}
        >
          <ContentBlock title="Top Aircraft" hide={account == undefined}>
            <TopAircraftContainer>
              {mostMatchesPlane != undefined ? (
                <TopAircraftCard
                  plane={mostMatchesPlane}
                  statDescription="total matches"
                >
                  <span>{mostMatchesPlaneStat}</span>
                </TopAircraftCard>
              ) : null}
              {highestWinRatePlane != undefined ? (
                <TopAircraftCard
                  plane={highestWinRatePlane}
                  statDescription="Win Rate"
                  subDescription={
                    getScreenSize() == "narrow"
                      ? "(Last 20)"
                      : "(Last 20 Matches)"
                  }
                >
                  <span>{highestWinRatePlaneStat}%</span>
                </TopAircraftCard>
              ) : null}
              {mostTrophiedPlane != undefined ? (
                <TopAircraftCard
                  plane={mostTrophiedPlane}
                  statDescription="total trophies"
                >
                  <TrophyText>
                    <span>{mostTrophiedPlaneStat}</span>
                    <img src={S3BucketPrefix + "trophy.png"} alt="" />
                  </TrophyText>
                </TopAircraftCard>
              ) : null}
            </TopAircraftContainer>
          </ContentBlock>
          <ContentBlock title="All Aircraft" hide={account == undefined}>
            {formattedDate != undefined ? (
              <RefreshDiv>
                Last Update: {formattedDate}. Log into the game to refresh
                stats.
              </RefreshDiv>
            ) : null}
            {planeStats != undefined ? (
              <StatsContainer rows={planeStats} />
            ) : null}
          </ContentBlock>
        </ContentDiv>
      </PageWrapper>
    );
  }
}
